import './App.scss';
import { Container, Row } from 'reactstrap'
import { useState } from 'react'
import useSystemTheme from 'react-use-system-theme';
import DepartureTable from './components/DepartureTable';
import InputForm from './components/InputForm';
import ApplicationHeader from './components/ApplicationHeader';

function App() {
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const systemTheme = useSystemTheme('dark');

  return (
    <Container fluid className='application-container d-flex flex-column'>
      <Row className={`application-header p-2 ${systemTheme}`}>
        <ApplicationHeader />
      </Row>
      <Row className={`input-form p-2 ${systemTheme}`}>
        <InputForm
          from={from}
          setFrom={setFrom}
          to={to}
          setTo={setTo}
          theme={systemTheme}
        />
      </Row>
      <Row className={`departure-table flex-grow-1 p-2 ${systemTheme}`}>
        <DepartureTable from={from} to={to} theme={systemTheme} />
      </Row>
    </Container>
  );
}

export default App;
