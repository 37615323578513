import { useState, useEffect } from 'react';
import { Row, Col, Label, Container } from 'reactstrap';
import Autocomplete from 'react-autocomplete';

// TODO fix z-height issue with autocomplete list

const InputForm = ({ setFrom, setTo, theme }) => {
  const [stations, setStations] = useState([]);
  const [stationPrefix, setStationPrefix] = useState('');
  const [inputFrom, setInputFrom] = useState('');
  const [inputTo, setInputTo] = useState('');

  const url = `https://transport.opendata.ch/v1/locations?query=${stationPrefix}&type=station`

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => setStations(data.stations.filter(station => station.id !== null)));
  }, [url])

  const handleFromChange = (inputFrom) => {
    setStationPrefix(inputFrom.target.value);
    setInputFrom(inputFrom.target.value);
  }

  const handleFromSelect = (inputFrom) => {
    setInputFrom(inputFrom)
    setFrom(inputFrom)
  }

  const handleToChange = (inputTo) => {
    setStationPrefix(inputTo.target.value);
    setInputTo(inputTo.target.value);
  }

  const handleToSelect = (inputFrom) => {
    setInputTo(inputFrom)
    setTo(inputFrom)
  }

  const handleOnFocus = (input) => {
    setStationPrefix(input.target.value);
  }

  const autocompleteMenuStyle = {
    borderRadius: '3px',
    boxShadow: '2px 2px 5px grey',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%',
  }

  const autocompleteRenderInput = (props) => (
    <input {...props} style={{ width: '100%' }} onFocus={handleOnFocus} />
  )

  return (
    <Container className="input-form">
      <Row>
        <Col sm="12" md="6" lg="6" className='d-flex align-items-center'>
          <Label className="m-2" for="inputFrom" >From</Label>
          <Autocomplete className="m-2"
            getItemValue={(station) => station.name}
            items={stations}
            renderItem={(station, isHighlighted) =>
              <div key={station.id} className={`autocomplete-item ${theme} ${isHighlighted ? 'highlighted' : ''}`}>
                {station.name}
              </div>
            }
            value={inputFrom}
            onChange={handleFromChange}
            onSelect={handleFromSelect}
            selectOnBlur={true}
            menuStyle={autocompleteMenuStyle}
            wrapperProps={{ className: 'flex-grow-1 autocomplete-input' }}
            renderInput={autocompleteRenderInput}
          />
        </Col>
        <Col sm="12" md="6" lg="6" className='d-flex align-items-center'>
          <Label className="m-2" for="inputTo">To</Label>
          <Autocomplete className="m-2"
            getItemValue={(station) => station.name}
            items={stations}
            renderItem={(station, isHighlighted) =>
              <div key={station.id} className={`autocomplete-item ${theme} ${isHighlighted ? 'highlighted' : ''}`} >
                {station.name}
              </div>
            }
            value={inputTo}
            onChange={handleToChange}
            onSelect={handleToSelect}
            selectOnBlur={true}
            menuStyle={autocompleteMenuStyle}
            wrapperProps={{ className: 'flex-grow-1 autocomplete-input' }}
            renderInput={autocompleteRenderInput}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default InputForm