import { useState, useEffect } from 'react';
import { Container, Table } from 'reactstrap';
import key from 'weak-key';

const DepartureTable = ({ from, to, theme }) => {
  const [trainConnections, setTrainConnections] = useState([]);
  const url = `https://transport.opendata.ch/v1/connections?from=${from}&to=${to}&limit=10&direct=1`

  // TODO link to sbb for detail 
  // example: https://www.sbb.ch/de/kaufen/pages/fahrplan/fahrplan.xhtml?suche=true&vias=%5B%22%22%5D&von=Z%C3%BCrich+HB&nach=Basel+SBB&viaField1=&datum=Fr%2C+30.04.2021&zeit=18%3A44&an=false

  useEffect(() => {
    const getConnections = () => {
      if (from.length > 0 && to.length > 0) {
        fetch(url)
          .then(response => response.json())
          .then(data => setTrainConnections(data.connections));
      }
    }

    getConnections();

    const interval = setInterval(() => {
      getConnections()
    }, 30000)

    return () => clearInterval(interval)

  }, [url, from, to])

  const tableItems = trainConnections.map((connection) => {
    const hasFromDelay = connection.from.delay > 0;
    const hasToDelay = connection.to.delay > 0;
    const departure = new Date(connection.from.departure)
      .toLocaleTimeString('ch-DE', { hour: '2-digit', minute: '2-digit', hour12: false })
      + (hasFromDelay ? `+${connection.from.delay}` : '');
    const arrival = new Date(connection.to.arrival)
      .toLocaleTimeString('ch-DE', { hour: '2-digit', minute: '2-digit', hour12: false })
      + (hasToDelay ? `+${connection.from.delay}` : '');

    return (
      <tr key={key(connection)}>
        <th scope="row">{connection.products.join(', ')}</th>
        <td>{connection.from.platform}</td>
        <td className={hasFromDelay ? 'text-danger' : undefined}>
          {departure}</td>
        <td className={hasToDelay ? 'text-danger' : undefined}>
          {arrival}
        </td>
        <td>{connection.duration.slice(3, 8)}</td>
      </tr>
    )
  })

  const tableHeader = (from !== '') && (to !== '') ? `Next connections from ${from} to ${to}` : 'Connections'

  return (
    <Container>
      <h2 className="m-2">{tableHeader}</h2>
      <Table responsive striped dark={theme === 'dark'}>
        <thead>
          <tr>
            <th>Train</th>
            <th>Platform</th>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {tableItems}
        </tbody>
      </Table>
    </Container>
  )
}

export default DepartureTable

