import { Container } from 'reactstrap';
import { MdTrain } from "react-icons/md";
import './../App.scss'

const ApplicationHeader = () => (
  <Container>
    <h1>Departures <MdTrain className='mb-1 mr-1' /></h1>
  </Container>
)

export default ApplicationHeader